<template>
  <AppLayout>
    <Breadcrumbs />
    <div class="container">
      <b-row class="forgot-password">
        <b-col md="10" lg="6">
          <h1>{{ $t("password_forgot") }}</h1>
          <p>
            {{ $t("forgot_password_text") }}
          </p>
          <b-form-group :label="$t('form_email') + '*'" label-for="email" :description="description">
            <b-form-input id="email" v-model="email" type="email" :state="emailState" aria-describedby="email-feedback"
              :placeholder="$t('form_email')"></b-form-input>
            <b-form-invalid-feedback id="email-feedback">
              {{ emailError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <div class="
              btn-wrap
              d-flex
              justify-content-md-between
              align-items-md-center
            ">
            <b-link class="back" to="/login">{{ $t("go_back") }}</b-link>
            <div class="btn-holder">
              <b-button @click="formSubmit" variant="warning" class="text-white form-submit-btn">{{
                $t("reset_my_password")
              }}</b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </AppLayout>
</template>

<style>
.forgot-password {
  & {
    margin-block: 55px;
  }

  h1 {
    font-size: 40px;
    font-style: italic;
    font-weight: 700;
    margin-bottom: 25px;
  }

  p {
    font-size: 15px;
    font-weight: 400;
  }

  label {
    font-size: 19.87px;
    font-weight: 400;
  }

  .btn-wrap {
    & {
      margin-top: 25px;
    }

    button.form-submit-btn {
      border-radius: 4px !important;
    }
  }

  input[type="email"] {
    border-radius: 5px;
    border-width: 1.42px;
  }
}
</style>

<script>
import AppLayout from "@/esf_kerkrade_vitanatura/core/components/AppLayout";
import Breadcrumbs from "@/esf_kerkrade_vitanatura/core/components/Breadcrumbs";
import { forgotPassword } from "@storefront/core/data-resolver/user";
export default {
  name: "ForgotPassword",
  components: {
    AppLayout,
    Breadcrumbs,
  },
  mounted() {
    const bcrumb = { current: this.$t("password_forgot"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.$t("password_forgot") },
        { name: "keywords", content: this.$t("password_forgot") },
        { name: "description", content: this.$t("password_forgot") },
      ],
      title: this.$t("password_forgot"),
    };
  },
  data() {
    return {
      email: "",
      emailState: null,
      emailError: "",
      description: "",
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  methods: {
    async formSubmit() {
      if (this.validateEmail()) {
        const retval = await forgotPassword(this.email);
        if (retval == true) {
          this.description = this.$t("reset_password_email_has_been_sent");
        } else {
          this.description = this.$t(
            "reset_password_email_could_not_been_sent"
          );
        }
      }
    },
    validateEmail() {
      if (this.email == "") {
        this.emailState = false;
        this.emailError = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.email)) {
        this.emailState = false;
        this.emailError = this.$t("email_is_invalid_field");
        return false;
      }
      this.emailState = true;
      return true;
    },
  },
};
</script>
